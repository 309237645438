import React from "react";
import DataTable from "../../../Components/DataTable";
import {Button, Col, Input, Label, Row} from "reactstrap";
import ShowPrice from "../../../Elements/ShowPrice";
import ShowEmail from "../../../Elements/ShowEmail";
import {ApiErrorThrow, debounce, defaultSnackbarOptions} from "../../../Config";
import InputWithIcon from "../../../Elements/InputWithIcon";
import TablePageFilterBar from "../../../Components/TablePageFilterBar";
import ConfirmationModalAlert from "../../../Components/ModalAlerts/ConfirmationModalAlert";
import CustomCheckbox from "../../../Elements/CustomCheckbox";
import classnames from "classnames";
import ClientModel from "../../../Models/ClientModel";
import {GetTitle} from "../../../Routes/AdminRoute";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {withSnackbar} from "react-simple-snackbar";
import PhoneNumberInput from "../../../Elements/PhoneNumberInput";
import ClientSelect from "../../../Elements/ClientSelect";
import {Select38Px} from "../../../Conts";
import GlSelect from "../../../Components/GlSelect";
import {BiLock, ImLock} from "react-icons/all";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../../../index";

const default_client_details = {
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    password: '',
    user_group: '',
    role: "ROLE_USER",
    mobile_phone: '',
    language: 'tr',
    "mobile_phone_country_code": "TR"
}
const isMobile = window.innerWidth < 720
const _columns = (self) => {
    let cols = [
        {
            Header: react_intl.formatMessage({id:"admin.clients.table_cols.id"}),
            accessor: 'id',
            sortKey: 'id',
            width: "10%",
            mobileWidth: "20%",
            Cell: row => {
                return <span className={"id-cell"}>{"#" + row.value}</span>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.clients.table_cols.name"}),
            accessor: null,
            width: "25%",
            mobileWidth: "40%",
            sortKey: 'firstname',
            Cell: data => {
                let row = data.row.original
                row = row.attributes
                let name = `${row.firstname} ${row.lastname}`
                return <span>{name}</span>;
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.clients.table_cols.email"}),
            accessor: 'attributes.email',
            sortKey: 'email',
            width: "20%",
            hideMobile: true,
            Cell: row => {
                return <ShowEmail email={row.value}/>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.clients.table_cols.phone"}),
            accessor: 'attributes.mobile_phone',
            width: "20%",
            hideMobile: true,
            Cell: row => {
                return row.value
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.clients.table_cols.balance"}),
            accessor: 'attributes.balance',
            sortKey: 'balance',
            width: "10%",
            hideMobile: true,
            Cell: row => {
                return <ShowPrice amount={row.value} currencyprefix={"₺"}/>
            }
        },
        {
            Header: react_intl.formatMessage({id:"admin.clients.table_cols.actions"}),
            accessor: null,
            width: "15%",
            mobileWidth: "40%",
            Cell: (data) => {
                let row = data.row.original
                row = row.attributes
                return <Button
                    size={"sm"}
                    color={"dark"}
                    onClick={() => {
                        self.props.history.push(`/admin/clients/${row.id}`)
                    }}><FormattedMessage id={"admin.clients.table_cols.actions.go_profile"} /></Button>
            }
        }
    ]
    return cols
}

class AdminClients extends React.Component {
    tableRef = React.createRef()
    state = {
        clientNameFilter: "",
        taxNumberFilter: "",
        clientEmailFilter: "",
        clientPhoneFilter: "",
        clientGroupFilter: "",
        setPw: false,
        user_info: default_client_details,
        createUser: false,
        userCreated: false,
        createdUserId: null,
        showPassiveAccounts: false,
        sendCredential: false,
        sendCredentialMail: false
    }
    columns = _columns(this)


    generatePassword = (l) => {
        if (typeof l !== "number")
            l = 8
        l *= -1
        return Math.random()
            .toString(36)
            .slice(l)
    }

    onClientFilterChanged = debounce(() => {
        this.tableRef.current.loadData()
    }, 500);

    register_form = React.createRef()


    constructor(props) {
        super(props);
        this.userInfoHandler = this.userInfoHandler.bind(this)
        this.createUserToggle = this.createUserToggle.bind(this)
        this.userCreatedToggle = this.userCreatedToggle.bind(this)
        this.sendCredentialToggle = this.sendCredentialToggle.bind(this)
        this.sendCredentialMailToggle = this.sendCredentialMailToggle.bind(this)
    }

    createUserToggle = () => {
        let state = {}
        state.createUser = !this.state.createUser
        state.user_info = default_client_details
        this.setState(state)
    }
    userCreatedToggle = () => {
        let state = {}
        state.userCreated = !this.state.userCreated
        this.setState(state)
    }

    sendCredentialToggle = () => {
        let state = {}
        state.sendCredential = !this.state.sendCredential
        this.setState(state)
    }
    sendCredentialMailToggle = () => {
        let state = {}
        state.sendCredentialMail = !this.state.sendCredentialMail
        this.setState(state)
    }

    setPwToggle = () => {
        let state = {}
        state.setPw = !this.state.setPw
        state.user_info = this.state.user_info
        state.user_info.password = this.generatePassword(8)
        this.setState(state)
    }

    userInfoHandler = (e, callback) => {
        let user_info = this.state.user_info
        user_info[e.target.name] = e.target.value
        this.setState({user_info}, () => {
            if (typeof callback === "function") {
                callback()
            }
        })
    }

    create = () => {
        let {openSnackbar} = this.props
        let user_info = {...this.state.user_info}

        if (typeof this.state.sendCredentialMail === "boolean")
            user_info['send_credential_mail'] = this.state.sendCredentialMail

        if (typeof this.state.sendCredential === "boolean")
            user_info['send_credential_sms'] = this.state.sendCredential

        ClientModel.create(this.state.user_info).then(r => r.json()).then(item => {
            this.setState({createdUserId: item.data.id}, this.userCreatedToggle)
        }).catch(r => ApiErrorThrow(r, openSnackbar))
    }

    componentDidMount() {
        document.title = GetTitle('Müşteriler')
        let {user_info} = this.state
        user_info.password = this.generatePassword(8)
        this.setState({
            user_info
        })
    }

    render() {
        let self = this
        let {openSnackbar} = this.props
        let pw = this.state.user_info.password;
        return <div className={"admin-clients-page"}>

            <ConfirmationModalAlert
                color={"success"}
                icon={"icon-feather-user-check"}
                title={"Müşteri Hesabı Oluşturuldu"}
                description={!this.state.setPw ? <div style={{fontSize: 18, margin: "10px 0"}}>
                    <i className={"icon-line-awesome-warning"}/>{" "}
                    Kullanıcı şifresi <CopyToClipboard text={pw} onCopy={() => {
                    openSnackbar("Şifre kopyalandı!")
                }
                }>
                    <strong className={"cursor-pointer"}>{pw}</strong>
                </CopyToClipboard> olarak ayarlanmıştır. Lütfen not ediniz.
                </div> : null}
                isOpen={this.state.userCreated}
                toggle={this.userCreatedToggle}
                buttontext={"Profile Git"}
                cancelButtonText={"Kapat"}
                buttonAction={() => {
                    this.props.history.push('/admin/clients/' + this.state.createdUserId)
                }}
            />

            <ConfirmationModalAlert
                isOpen={this.state.createUser}
                title={"Yeni Müşteri Ekle"}
                description={"Yeni müşteri kaydı için formu doldurun."}
                icon={"icon-feather-user-plus"}
                toggle={this.createUserToggle}
                buttonAction={() => {
                }}
                formName={"new-client-form"}
            >
                <form
                    id={"new-client-form"}
                    name={"new-client-form"}
                    ref={this.register_form}
                    method={"post"}
                    onSubmit={(e) => {
                        e.preventDefault()
                        this.create()
                    }}
                >
                    <Row>
                        <Col sm={6}>
                            <Label>İsim</Label>
                            <InputWithIcon
                                icon={"icon-line-awesome-user"}
                                name={"firstname"}
                                required
                                onChange={this.userInfoHandler}
                            />
                        </Col>

                        <Col sm={6}>
                            <Label>Soyisim</Label>
                            <InputWithIcon
                                required
                                icon={"icon-line-awesome-user"}
                                name={"lastname"}
                                onChange={this.userInfoHandler}
                            />
                        </Col>

                        <Col sm={12} className={"text-left"}>
                            <Label>E-posta</Label>
                            <InputWithIcon
                                type={"email"}
                                required
                                icon={"icon-material-outline-email"}
                                name={"email"}
                                onChange={this.userInfoHandler}
                            />
                        </Col>
                        <Col sm={12} className={"text-left"}>
                            <Label>Telefon</Label>
                            <PhoneNumberInput
                                required
                                value={""} onChange={(val) => {
                                if (typeof val !== "object")
                                    val = {}
                                this.userInfoHandler({
                                    target: {
                                        name: "mobile_phone_country_code",
                                        value: val.country
                                    }
                                }, function () {
                                    self.userInfoHandler({
                                        target: {
                                            name: "mobile_phone",
                                            value: val.number
                                        }
                                    })
                                })
                            }}/>
                        </Col>
                    </Row>
                    <Row className={classnames("text-left p-1 mt-2 mb-2 rounded", {"bg-light border": this.state.setPw})}>
                        <Col sm={12} md={6}>
                            <Label onClick={this.setPwToggle} className={"margin-top-20 cursor-pointer"}>
                                <ImLock />
                                Şifreyi ayarla</Label> {" "}
                            <CustomCheckbox onChange={() => this.setPwToggle()} checked={this.state.setPw}/>
                        </Col>
                        <Col sm={12} md={6} className={classnames("text-left", {"d-none": !this.state.setPw})}>
                            <Label>Şifre:</Label> {" "}
                            <InputWithIcon
                                value={this.state.user_info.password}
                                name={"password"} type={"password"}
                                icon={"icon-feather-lock"}
                                onChange={this.userInfoHandler}
                            />
                        </Col>
                    </Row>
                    <Row className={"pt-2 mb-1"}>
                        <Col sm={12} md={6}>
                            <Label onClick={this.sendCredentialToggle} className={"margin-top-20 cursor-pointer"}><strong>SMS</strong> ile paylaş</Label> {" "}
                            <CustomCheckbox onChange={() => this.sendCredentialToggle()}
                                            checked={this.state.sendCredential}/>
                        </Col>
                        <Col sm={12} md={6}>
                            <Label onClick={this.sendCredentialMailToggle} className={"margin-top-20 cursor-pointer"}><strong>E-posta</strong> ile paylaş</Label> {" "}
                            <CustomCheckbox onChange={() => this.sendCredentialMailToggle()}
                                            checked={this.state.sendCredentialMail}
                            />
                        </Col>
                    </Row>
                </form>
            </ConfirmationModalAlert>

            <TablePageFilterBar
                title={"Müşteriler"}
                description={""}
            >
                <Col sm={3}>
                    <Label>Müşteri Adı, E-posta veya Telefon</Label>
                    <InputWithIcon
                        icon={" icon-material-outline-search"}
                        type={"text"} name={"clientName"}
                        onChange={(e) => {
                            this.setState({clientNameFilter: e.target.value})
                            this.onClientFilterChanged()
                        }}
                    />
                </Col>

                <Col sm={2} className={"text-left"}>
                    <Label>Vergi No</Label> {" "}
                    <InputWithIcon
                        icon={" icon-material-outline-search"}
                        type={"text"} name={"taxNumberFilter"}
                        onChange={(e) => {
                            this.setState({taxNumberFilter: e.target.value})
                            this.onClientFilterChanged()
                        }}
                    />
                </Col>

                <Col sm={2} className={"text-left"}>
                    <Label>Müşteri Grubu</Label> {" "}
                    <GlSelect
                        styles={Select38Px}
                        isMulti={true}
                        defaultvalue={null}
                        url={'/api/v2/user-group'}
                        placeholder={"Seçiniz"}
                        onChange={(e) => {
                            this.setState({
                                clientGroupFilter: e.map(item => item.value).join(",")
                            }, () => {
                                if (!e.length)
                                    this.onClientFilterChanged()
                            })
                        }}
                        onMenuClose={() => {
                            this.onClientFilterChanged()
                        }}
                        isClearable={true}
                        searchKey={"name"}
                    />
                </Col>

                <Col sm={2} className={"text-left"} style={{
                    lineHeight: "14px",
                    marginTop: "-10px"
                }}>
                    <p>&nbsp;</p>
                    <CustomCheckbox
                        spanstyle={{
                            marginTop: 0,
                            top: 0,
                        }}
                        labelstyle={{
                            lineHeight: "14px"
                        }}
                        inputstyle={{}}
                        label={"Kapatılanlar dahil edilsin"} onChange={(e) => {
                        this.setState({
                            showPassiveAccounts: e.target.checked
                        }, this.onClientFilterChanged)
                    }}/>
                </Col>

                <Col sm={3} className={"text-right"}>
                    <Label className={"w-100"}>&nbsp;</Label>
                    <Button
                        onClick={this.createUserToggle}
                        color={"dark"}>Yeni Müşteri Ekle</Button>
                </Col>
            </TablePageFilterBar>

            <div className={"container"}>
                <DataTable
                    pageSize={25}
                    order={"desc"}
                    orderBy={"id"}
                    ref={this.tableRef}
                    columns={this.columns}
                    rowClick={(row) => {
                        row = row.original
                        this.props.history.push(`/admin/clients/${row.id}`)
                    }}
                    dataQuery={{
                        url: "/api/v2/user",
                        filters: {
                            concat: this.state.clientNameFilter,
                            tax_number: this.state.taxNumberFilter,
                            role: "ROLE_USER",
                            user_groups: this.state.clientGroupFilter,
                            include: this.state.showPassiveAccounts ? "suspended" : ''
                        }
                    }}/>
            </div>

        </div>;
    }
}

export default withSnackbar(AdminClients, defaultSnackbarOptions)
